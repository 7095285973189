import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

//Slice

const slice = createSlice({
  name: "datasrev",
  initialState: {
    user: null,
    orga: null,
    vehicules: [],
    stations: [],
    users: [],
    routers: [],
    accessreaders: [],
    accessreadersdata: [],
    stationaccessuser: [],
    accessuserdetail: [],
    bookingdata: [],
  },
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload;
    },
    getOrga: (state, action) => {
      state.orga = action.payload;
    },
    getUsers: (state, action) => {
      state.users = action.payload;
    },
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateUser: (state, action) => {
      state.users = state.users.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    getVehicule: (state, action) => {
      state.vehicules = action.payload;
    },
    addVehicule: (state, action) => {
      state.vehicules = [...state.vehicules, action.payload];
    },
    removeVehicule: (state, action) => {
      state.vehicules = state.vehicules.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateVehicule: (state, action) => {
      state.vehicules = state.vehicules.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    getStation: (state, action) => {
      state.stations = action.payload;
    },
    getRouter: (state, action) => {
      state.routers = action.payload;
    },
    getReader: (state, action) => {
      state.accessreaders = action.payload;
    },
    getReaderData: (state, action) => {
      state.accessreadersdata = action.payload;
    },
    getAccessUserData: (state, action) => {
      state.stationaccessuser = action.payload;
    },
    getUserDetails: (state, action) => {
      state.accessuserdetail = action.payload;
    },
    getBookingData: (state, action) => {
      state.bookingdata = action.payload;
    },
  },
});

export default slice.reducer;

const {
  getUser,
  getOrga,
  getUsers,
  addUser,
  removeUser,
  updateStateUser,
  getVehicule,
  addVehicule,
  removeVehicule,
  updateStateVehicule,
  getStation,
  getRouter,
  getReader,
  getReaderData,
  getAccessUserData,
  getUserDetails,
  getBookingData,
} = slice.actions;

function CheckConfigAxios() {
  //We check if token is loaded into Axios Config
  if (axiosInstance.defaults.headers.common.Authorization == null) {
    //console.log("NO TOKEN IN AXIOS CONFIG, NEED TO LOAD ONE !");
    let token = localStorage.getItem("accessToken");
    if (isValidToken(token)) {
      axiosInstance.defaults.headers.common.Authorization = token;
    } else {
      console.log("YOU HAVE TO RECONNECT");
    }
  } else {
    if (!isValidToken(axiosInstance.defaults.headers.common.Authorization)) {
      console.log("YOU HAVE TO RECONNECT");
    }
  }
}

//Actions
export const fetchUser = () => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance.get("/userinfo").then((response) => {
      dispatch(getUser(response.data));
      //localStorage.setItem("id_orga", response.data.id_orga);
      localStorage.setItem("id_orga", response.data.ad_orga);

      if (response.data.ad_orga !== "#") {
        dispatch(fetchOrga());
        dispatch(fetchVehicules());
        dispatch(fetchStations());
        dispatch(fetchUsers());
      }

      //dispatch(fetchFiles());

      //dispatch(fetchDatas("2022-12-01", "2022-12-31", 4));
      //dispatch(fetchPimlogs("2022-12-01", "2022-12-31", 4));
      //dispatch(fetchDatasDay("2022-12-19", 4));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchOrga = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  if (user.ad_orga === "*") {
    dispatch(getOrga({ Message: "No Data" }));
    return;
  }
  try {
    axiosInstance
      .get(`/organisation/${user.ad_orga}`)
      .then((response) => dispatch(getOrga(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchUsers = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  if (user === null) {
    dispatch(getUsers({ Message: "No Data" }));
    return;
  }
  if (user !== null) {
    if (user.ad_orga === "*") {
      dispatch(getUsers({ Message: "No Data" }));
      return;
    }
  }
  try {
    axiosInstance
      .get(`/organisation/${user.ad_orga}/user`)
      .then((response) => dispatch(getUsers(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const createUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  try {
    axiosInstance
      .post(`organisation/${user.ad_orga}/user`, data)
      .then((response) => dispatch(addUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  try {
    axiosInstance
      .put(`organisation/${user.ad_orga}/user/${data.data_type.slice(5)}`, data)
      .then((response) => dispatch(updateStateUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  try {
    axiosInstance
      .delete(`organisation/${user.ad_orga}/user/${data.data_type.slice(5)}`)
      .then((response) => dispatch(removeUser(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchVehicules = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  if (user === null) {
    dispatch(getVehicule({ Message: "No Data" }));
    return;
  }
  if (user !== null) {
    if (user.ad_orga === "*") {
      dispatch(getVehicule({ Message: "No Data" }));
      return;
    }
  }
  try {
    axiosInstance
      .get(`/organisation/${user.ad_orga}/vehicule`)
      .then((response) => dispatch(getVehicule(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const createVehicule = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  try {
    axiosInstance
      .post(`organisation/${user.ad_orga}/vehicule`, data)
      .then((response) => dispatch(addVehicule(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateVehicule = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  try {
    axiosInstance
      .put(
        `organisation/${user.ad_orga}/vehicule/${data.data_type.slice(5)}`,
        data
      )
      .then((response) => dispatch(updateStateVehicule(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteVehicule = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  try {
    axiosInstance
      .delete(
        `organisation/${user.ad_orga}/vehicule/${data.data_type.slice(5)}`
      )
      .then((response) => dispatch(removeVehicule(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchStations = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().datasrev.user;
  if (user.ad_orga === "*") {
    dispatch(getStation({ Message: "No Data" }));
    return;
  }
  try {
    axiosInstance
      .get(`/organisation/${user.ad_orga}/station`)
      .then((response) => dispatch(getStation(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchRouters =
  (id_station, lid_orga) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().datasrev.user;
    if (user.ad_orga === "*") {
      dispatch(getRouter({ Message: "No Data" }));
      return;
    }
    try {
      axiosInstance
        .get(`/organisation/${lid_orga}/station/${id_station}/router`)
        .then((response) => dispatch(getRouter(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchAccessReader =
  (id_station, lid_orga) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().datasrev.user;
    if (user.ad_orga === "*") {
      dispatch(getReader({ Message: "No Data" }));
      return;
    }
    try {
      axiosInstance
        .get(`/organisation/${lid_orga}/station/${id_station}/access`)
        .then((response) => dispatch(getReader(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchAccessReaderData =
  (id_station, lid_orga, id_reader) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().datasrev.user;
    if (user.ad_orga === "*") {
      dispatch(getReaderData({ Message: "No Data" }));
      return;
    }
    //console.log(`/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`);
    try {
      axiosInstance
        .get(
          `/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`
        )
        .then((response) => dispatch(getReaderData(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchAccessUserData =
  (id_station, lid_orga) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().datasrev.user;
    if (user.ad_orga === "*") {
      dispatch(getAccessUserData({ Message: "No Data" }));
      return;
    }
    //console.log(`/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`);
    try {
      axiosInstance
        .get(`/organisation/${lid_orga}/station/${id_station}/useraccess`)
        .then((response) => dispatch(getAccessUserData(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchBookingData =
  (id_station, lid_orga) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().datasrev.user;
    if (user.ad_orga === "*") {
      dispatch(getBookingData({ Message: "No Data" }));
      return;
    }
    try {
      axiosInstance
        .get(`/organisation/${lid_orga}/station/${id_station}/userbooking`)
        .then((response) => dispatch(getBookingData(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchUserDetails = (id_user) => async (dispatch) => {
  CheckConfigAxios();
  //console.log(`/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`);
  try {
    axiosInstance
      .get(`/customer/${id_user}`)
      .then((response) => dispatch(getUserDetails(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const allowUserAccess = (id_access) => async () => {
  CheckConfigAxios();
  //customer_1338_access_1391
  const accessIds = id_access.split("_");

  const data = {
    status: "allowed",
  };

  try {
    axiosInstance
      .put(`customer/${accessIds[1]}/access/${accessIds[3]}`, data)
      .then((response) => console.log(response.data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const denyUserAccess = (id_access) => async () => {
  CheckConfigAxios();
  //customer_1338_access_1391
  const accessIds = id_access.split("_");

  const data = {
    status: "waiting",
  };

  try {
    axiosInstance
      .put(`customer/${accessIds[1]}/access/${accessIds[3]}`, data)
      .then((response) => console.log(response.data));
  } catch (e) {
    return console.error(e.message);
  }
};

/*
export const fetchFiles = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  if (user.id_orga === "*") {
    dispatch(getFiles({ Message: "No Data" }));
    return;
  }
  try {
    axiosInstance
      .get(`/organisations/${user.id_orga}/files`)
      .then((response) => dispatch(getFiles(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDatas =
  (begin, end, device) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/devices/${device}/datas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00&bin=1d`
        )
        .then((response) => dispatch(getDatas(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchPimlogs =
  (begin, end, device) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/devices/${device}/pimlog?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00`
        )
        .then((response) => dispatch(getPimlogs(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchDatasDay = (day, device) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .get(
        `organisations/${user.id_orga}/devices/${device}/datas?begin_iso8601=${day}T00:00:00&end_iso8601=${day}T23:59:59&bin=1h`
      )
      .then((response) => dispatch(getDatasDay(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const exportDatas =
  (begin, end, device, bin, mail_to, format, local_timezone) =>
  async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/devices/${device}/exportdatas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T23:59:00&bin=${bin}&mail_to=${mail_to}&format=${format}&local_timezone=${local_timezone}`
        )
        .then((response) => dispatch(getDataExport(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

  */
